.header_about {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 3%;
  }

  .about_img {
    height: 35vh;
    width: 100%;
    border-width: 6px;
  }
  .header_about h1 {
    position: absolute;
    font-size: 5rem;
    color: rgb(240, 235, 235);
  }

  @media only screen and (max-width: 768px) { 
    .section2 {
      flex-direction: column;
      height: auto !important;
    }
    .pic_section {
      /* width: 100% !important;
      position: absolute !important; */
      display: none;
    }
    /* .pic_section img:first-child {
     display: none;
     
    } */
    .text_about {
      width: 100% !important;
      height: auto !important;
      justify-content: center !important;
      align-items: center !important;
      text-align: center;
      margin-left: 0 !important;
    }
    .text_about h3:first-child {
      font-size: 4rem !important;
      align-self: center;
      margin-top: 5%;
    }
    .believe {
      font-size: 4rem !important;
      text-align: center;
      align-self: center;
      /* margin-top: 0; */
    }

    .text_words {
      width: 95%;
      text-align: center;
      padding-right: 0 !important;
      font-size: 2rem !important;
    }
    .respone_img {
      display: flex !important;
      width: 60%;
      height: 20%;
    }
    .section3 {
      height: auto !important;
    }
    .social-container {
      padding-left: 0 !important;
    }
    #response {
      margin-left: 0 !important;
    }
    #bottom_sec {
      width: auto !important;
    }
    .bottom_pic {
      width: 60% !important;
    }
  
  }

  @media (max-width: 428px) {
    .about_img {
      height: 25vh
    }
    .section2 {
      flex-direction: column;
      height: auto !important;
    }
    .pic_section {
      /* width: 100% !important;
      position: absolute !important; */
      display: none;
    }
    /* .pic_section img:first-child {
     display: none;
     
    } */
    .text_about {
      width: 100% !important;
      height: auto !important;
      justify-content: center !important;
      align-items: center !important;
      text-align: center;
      margin-left: 0 !important;
    }
    .text_about h3:first-child {
      font-size: 3rem !important;
      align-self: center;
      margin-top: 5%;
    }
    .believe {
      font-size: 3rem !important;
      text-align: center;
      align-self: center;
      /* margin-top: 0; */
    }

    .text_words {
      width: 95%;
      text-align: center;
      padding-right: 0 !important;
      font-size: 1.3rem;
    }
    .respone_img {
      display: flex !important;
      width: 80%;
      height: 20%;
    }
    .section3 {
      height: auto !important;
    }
    .social-container {
      padding-left: 0 !important;
    }
    #response {
      margin-left: 0 !important;
    }
    #bottom_sec {
      width: auto !important;
    }
    .bottom_pic {
      width: 70% !important;
    }
  }