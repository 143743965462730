.title {
    position: absolute;
    top: 5vh;
    color: white;
    display: flex;
    font-family: 'Silka Bold', sans-serif, 'Helvetica Bold', Icons;
    /* height: 20%; */
    margin-top: 5%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    font-size: 2em;
  }
  .title h1 {
    font-weight: 300;
    margin-top: -5%;
  }

  .loginButton button {
    width: 16vw;
    height: auto;
    background-color: transparent;
    color: white;
    border-color: white;
    border-width: 2px;
    border-radius: 1vh;
    border-style: solid;
    font-size: 1em;
    font-family: 'Silka Bold', sans-serif, 'Helvetica Bold', Icons;
    font-weight: bold;
    margin-top: 45vh;
  }
  .choiceButton {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 52vh;
  }
  .choiceButton button{
    width: 12vw;
    height: auto;
    background-color: transparent;
    color: white;
    border-color: white;
    border-width: 2px;
    border-radius: 1vh;
    border-style: solid;
    font-size: 1em;
    font-family: 'Silka Bold', sans-serif, 'Helvetica Bold', Icons;
    font-weight: bold;
  }
  .loginButton button:hover {
    background-color: rgba(250, 249, 246, 0.3);
    transition: 0.7s;
    cursor: pointer;
  }

  @media (max-width: 1460px) { 
    /* .loginButton button {
      margin-top: 24vh;
    }

    .choiceButton {
      margin-top: 32vh;
    } */

    .choiceButton {
      /* flex-direction: column; */
      /* justify-content: space-evenly;
      align-items: center; */
      margin-top: 32vh;
    }
  }
  @media (max-width: 1110px) { 
   

    .choiceButton button{
      width: 16vw;
      font-size: .8em;
    }

  }

  @media (max-width: 1050px) { 
   

    .choiceButton {
      margin-top: 25vh;
    }

  }
  @media (max-width: 900px) { 

    .choiceButton {
      /* flex-direction: column; */
      /* justify-content: space-evenly;
      align-items: center; */
      margin-top: 10vh !important;
    }
    .choiceButton button{
      width: 20vw;
      height: 5vh;
      font-size: 1.5rem;
      margin-top: 3%;
    }

  }

  @media (max-width: 768px) {
    .title {
      height: auto !important;
    }
   
    .title h1 {
      margin-top: 3%;
      font-size: 2.5rem;
      text-align: center;
    }
    .loginButton button {
      width: 25vw;
      height: 6vh;
      font-size: 1.5rem;
      margin-top: 24vh;

    }
    .choiceButton {
      /* flex-direction: column; */
      justify-content: space-evenly;
      align-items: center;
      margin-top: 32vh !important;
    }
    .choiceButton button{
      width: 25vw;
      height: 6vh;
      font-size: 1.5rem;
      /* margin-top: 6%; */
    }
  }

  @media (max-width: 435px) {
    .title {
      height: auto !important;
      margin-top:  0 !important;
      padding-bottom: 2%;
    }
    .header img {
      height: auto;
    }
   
    .title h1 {
      /* margin-top: 10%; */
      font-size: 2rem;
      text-align: center;
    }
    .loginButton button {
      width: 30vw;
      height: 5vh;
      font-size: 1.3rem;
      margin-top: 3vh !important;
    }
    .choiceButton {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      margin-top: 1vh !important;
    }
    .choiceButton button{
      width: 40vw;
    }
    @media (max-width: 380px) { 
      .loginButton button {
        
        margin-top: 1vh !important;
      }

      .choiceButton {
        margin-top: 0 !important;
      }

      .choiceButton button{
        width: 35vw;
       
        /* margin-top: 6%; */
      }
    }
  }