.member {
    width: 100%;
    position: relative;
    margin-bottom: 10%;
  }
  .member img {
    height: 90vh;
    width: 100%;
  }
  .member .become {
    position: absolute;
    padding-left: 3%;
    top: 25%;
    font-size: 2em;
    color: rgb(255, 255, 255, 0.9);
  }

  .become h5 {
    font-size: 0.7em;
    margin-top: -6%;
    font-weight: 300;
  }

  .member button {
    width: 16vw;
    height: 5vh;
    background-color: transparent;
    position: absolute;
    top: 60%;
    right: 10%;
    color: white;
    border-color: white;
    border-width: 2px;
    border-radius: 1vh;
    border-style: solid;
    font-size: 1em;
    font-family: 'Silka Bold', sans-serif, 'Helvetica Bold', Icons;
    font-weight: bold;
    /* margin-top: 36vh; */
  }
  .member button:hover {
    background-color: rgba(250, 249, 246, 0.3);
    transition: 0.7s;
    cursor: pointer;
  }
  @media only screen and (max-width: 950px) { 
    .member {
      margin-bottom: 57%;
    }
  }

  @media only screen and (max-width: 768px) { 
    .member {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      align-content: center;
      margin-bottom: 35%;
    }
    .member img {
      height: 70vh;
      width: 150vw;
      object-fit: contain;
    }

    .member .become {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: flex-start;
      margin-top: -35%;
     
    }
    .member h2 {
      color:black;
      font-size: 2.3rem;
    }
    .member h5 {
      color:black
    }
    .member button {
    
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      align-self: center;
      width: 35vw;
      height: 6vh;
      right: auto;
      top: auto;
      font-size: 1.2em;
    }

  }
  

  @media (max-width: 435px) { 
    .member {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      align-content: center;
    }
    .member img {
      height: 70vh;
      width: 150vw;
      object-fit: contain;
    }

    .member .become {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: flex-start;
      margin-top: -35%;
      padding-left: 0 !important;
      /* padding-left: 3%;
      top: 25%;
      font-size: 2em;
      color: rgb(255, 255, 255, 0.9); */
    }
    .member h2 {
      color:black;
      font-size: 2.3rem;
    }
    .member h5 {
      color:black
    }
    .member button {
    
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      align-self: center;
      width: 35vw;
      height: 6vh;
      right: auto;
      top: auto;
      font-size: 1.2em;
      /* color: white;
      border-color: white;
      border-width: 2px;
      border-radius: 1vh;
      border-style: solid;
      font-size: 1em;
      font-family: 'Silka Bold', sans-serif, 'Helvetica Bold', Icons;
      font-weight: bold; */
      /* margin-top: 36vh; */
    }
  }