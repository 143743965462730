.instrucBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .submitVideo {
    /* background: transparent; */
    background-color: rgb(230, 244, 241);
    color: black;
    border-color: white;
    border-width: 2;
    border-radius: 1vh;
    border-style: solid;
    width: 16vw;
    height: 5vh;
    font-size: 1.4em;
    margin-bottom: 5%;
  }
  .submitVideo:hover {
    background-color: rgba(250, 249, 246, 0.5);
    transition: 0.7s;
    cursor: pointer;
  }
  .upload_section {
    border-radius: 1vh;
    border-style: solid;
    border-color: black;
    border-width: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;
    width: 80%;
    position: fixed;
    top: 10%;
    bottom: 0;
    z-index: 1000;
    background-color: white;
  }
  
  .upload_icon {
    display: flex;
    font-size: 6em;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background-color: rgb(192, 192, 192);
    justify-content: center;
    align-items: center;
    margin-top: 3%;
  }
  .upload_words {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .upload_title {
    border-bottom: solid black;
    border-width: 0.5;
    font-size: 1.3rem;
    width: 100%;
    float: left;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
  }
  
  .upload_title h3 {
    margin-left: 3%;
  }
  .upload_header_icon {
    margin-right: 3%;
    font-size: 1.5em;
  }
  .submission {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3%;
  }
  
  .submission h1 {
    margin-top: 5%;
  }
  @media only screen and (max-width: 768px) { 
    .instrucBody h1[style]{
      margin-top: 10% !important;
      font-size: 2.3rem !important;
      text-align: center;
    }
   
    .vid {
      width: 100vw !important;
    }
    .instruc_instruc {
      height: auto !important;
      padding-bottom: 5%;
    }
    .instru-pic {
      display: none !important;
    }
    .list ol{
      width: 90% !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    .list h2[style]{
      font-size: 2.3rem !important;
      text-align: center !important;
    }
    .foot h2 {
      font-size: 2.2rem !important;
    }
    .foot button {
      height: 8vh;
      width: 40vw;
    }
    .list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .res_picture {
      display: flex !important;
      width: 70%;
      text-align: center;
    }
    .upload_section {
      height: 90vh;
      top: 5%;
    }
    .upload_title {
      font-size: 1rem;
    }
    .upload_section button  {
      height: 6vh !important;
      width: 40vw !important;
    }
    .thanku_container {
      width: 80% !important;
      height: 60vh !important;
    }
    .thanku_container  button{
      height: 6vh !important;
      width: 40vw !important;
    }
    .service_body {
      width: 90% !important;
    }

     }

  @media (max-width: 428px) { 
 
    .instrucBody h1[style]{
      margin-top: 16% !important;
      font-size: 1.7rem !important;
      text-align: center;
    }
    .video_sec {
      height: 45vh !important;
    }
    .video_sec p{
      text-align: center;
    }
    .vid {
      width: 100vw !important;
      height: 100% !important;
    }
    .instruc_instruc {
      height: auto !important;

    }
    .instru-pic {
      display: none !important;
    }
    .list {
      padding: 20px;
    }
    .list ol{
      width: 98% !important;
      
    }
    .list h2[style]{
      font-size: 1.8rem !important;
      text-align: center !important;
    }
    .service_body {
      width: 98% !important;
    }
    .foot h2 {
      font-size: 1.8rem !important;
    }
    .foot button {
      height: 8vh;
      width: 40vw;
    }
    .upload_section {
      height: 100vh;
     
      top: 5%;
      
    }
    .upload_section button  {
      height: 6vh !important;
      width: 40vw !important;
    }
    .VideoInput_footer {
      display: none;
    }
    .thanku_container {
      width: 100% !important;
      height: 60vh !important;
    }
    .thanku_container  button{
      height: 6vh !important;
      width: 40vw !important;
    }

  }
  @media (max-width: 290px) {  

  }