.navbar {
  position: fixed;

  border-width: 3px;
  width: 100%;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* align-items: center; */
  /* font-size: 1.5em; */
  font-family: "Silka Bold", sans-serif, "Helvetica Bold", Icons;
  font-weight: 700;
  background-color: white;
  z-index: 110;
}
.headLogo {
  padding-left: 2%;
  border-width: 3px;
  width: 25%;
}
.headLogo img {
  width: 45%;
  height: 100%;
  /* border-color: red;
    border-style: solid;
    border-width: 3px; */
}
.navBarInstuc {
  position: fixed;

  border-width: 3px;
  width: 100%;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* font-size: 1.5em; */
  font-family: "Silka Bold", sans-serif, "Helvetica Bold", Icons;
  font-weight: 700;
  background-color: #418cab;
  z-index: 110;
}

.nav {
  /* margin-left: 50%; */
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* align-self: center; */
  /* margin-right: 50vw; */
}

.nav h4:hover {
  color: rgba(52, 50, 49, 0.5);
  transition: 0.7s;
  cursor: pointer;
}

.nav_menu {
  position: absolute;
  right: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  /* height: 100%; */
  width: 15%;
}

.nav_clicks h3:hover {
  color: rgba(250, 249, 246, 0.6);
  transition: 0.6s;
  cursor: pointer;
}

/* nav menu sidebar */

.menuContainer {
  display: none;
  /* position: relative;
    justify-content: center;
    align-items: center;
    left: 20%;
    margin-top: 1.5%; */
}
.menuContainer:hover {
  cursor: pointer;
}

.menu {
  width: 30px;
  height: 3px;
  background-color: black;
  margin: 6px 0;
}

@media (max-width: 768px) {
  .headLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
  }
  .headLogo img {
    width: 70%;
    margin-top: 0;
  }
  .nav {
    display: none;
  }
  .menuContainer {
    display: inline-block;
    position: relative;
    justify-content: center;
    align-items: center;
    right: -68%;
    margin-top: 2%;
  }
  .nav_menu {
    width: 50%;
  }
}

@media (max-width: 435px) {
  /* .nav {
    display: none;
  } */
  .navbar {
    justify-content: space-between;
    padding-bottom: 1%;
  }
  .menuContainer {
    left: -4%;
  }
  /* .nav_menu {
    width: 50%;
  } */
}
