.loginContainer {
    display: flex;
    /* border-color: #282c34;
    border-width: 3;
    border-style: solid;
    border-radius: 3vh; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 3vh;
    height: 80vh;
    width: 70%;
    /* justify-content: center;
    align-content: center;
    align-items: center; */
    flex-direction: row;
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    align-self: center;
    z-index: 10;
    /* animation: slideMe 0.7s ease-in;
    overflow: hidden;
    backdrop-filter: blur(10px); */
  }

  .loginContainer img {
    height: 20px;
  
    /* width: 30px; */
  }
  .smallLogo {
    /* border-color: red;
    border-width: 3;
    border-style: solid; */
    width: 15vw;
    height: 12%;
    background: url('../assets/TPH-echurch-logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    margin-top: 4vh;
    margin-left: 4vh;
    z-index: 11;
  }
  .loginSection {
    /* border-color: red;
    border-width: 3;
    border-style: solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    width: 50%;
    height: 100%;
    /* box-shadow: rgba(0,0,0,0.8) 0 0 10px; */
    border-top-left-radius: 3vh;
    border-bottom-left-radius: 3vh;
  }
  label {
    margin: 1.3vh;
  }
  input {
    margin: 1vh;
    width: 15vw;
    height: 2.8vh;
    border-top: none;
    border-right: none;
    border-left: none;
  }
  label,
  input {
    /* display: flex;
    flex-direction: column; */
  
    display: inline block;
    clear: left;
    width: 250px;
    text-align: left;
    margin: 1.5%;
  }
  .banner {
    position: absolute;
    right: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
    background-color: #440f61;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 3vh;
    border-bottom-right-radius: 3vh;
    /* box-shadow: rgba(0,0,0,0.8) 0 0 10px; */
  }
  .banner-img {
    position: absolute;
    width: 100%;
    height: 100%;
    background: no-repeat 50% 50%;
    -webkit-backgound-size: cover;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    /* animation: animate 15s linear infinite; */
    opacity: 1;
    /* transform: scale(1.2); */
  }
  .banner-img2 {
    position: absolute;
    width: 100%;
    height: 100%;
    background: no-repeat 50% 50%;
    -webkit-backgound-size: contain;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    /* animation: animate 15s linear infinite; */
    opacity: 1;
  }
  @media (max-width: 1460px) { 
    .loginButton button {
      margin-top: 24vh;
    }
    .loginContainer {
      width: 90%;
     }
    
    input {
      height: 5vh;
      font-size: 18px;
    }
  }

  @media (max-width: 900px) { 
    .loginContainer {
      width: 100%;
      height: 70vh;
     }

  }

  @media only screen and (max-width: 768px) { 
 .loginContainer {
  width: 90%;
 }

 input {
  height: 5vh;
  font-size: 18px;
}

  }

  @media (max-width: 500px) {
    .body {
      overflow-x: hidden !important;
    }
    .loginContainer {
      
      flex-direction: column;
      height: 90vh;
      
      align-items: center;
     
      width: 90%;
      /* position: absolute;
      bottom: 65%; */
    }
    .banner {
      display: none;
    }
    .banner .banner-img[style] {
      display: none;
      background-image: none;
    }
    .loginSection {
      width: 100vw;
      height: 100vh;
    }
    .loginSection p {
      margin-top: 7%;
    }
    .member_error{
      margin-top: 0 !important
    }
    .loginSection h3 {
      margin-bottom: -5%;
      margin-top: 18%;
     
    }
    .smallLogo {
      width: 35vw;
      margin-top: 0;
    }
    label {
     margin-bottom: 10%;
    }
    input {
      height: 5vh;
      font-size: 18px;
    }
    .login_button[style] {
      width: 25vw !important
    }
  }