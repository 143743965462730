.footer {
    /* display: flex; */
    margin-top: 10%;
    /* justify-content: center;
    align-items: center; */
    border-top: #211f1f50;
    border-top-width: 1px;
    border-top-style: solid;
    height: auto;
    width: 100%;
    background-color: #462472;
    position: absolute;
    /* top: 100vh; */
    bottom: 0;
    /* background-image: linear-gradient(to bottom right, #440F61, #CC415C); */
  
    color: white;
  }
  .about_footer {
    display: flex;
    /* margin-top: -1%; */
    justify-content: center;
    align-items: center;
    height: 34vh;
    width: 100%;
    background-color: #462472;
    /* position: absolute; */
    /* top: 100vh; */
    bottom: 0;
    /* background-image: linear-gradient(to bottom right, #440F61, #CC415C); */
  
    color: white;
  }
  .instrucBody footer {
    display: flex;
    /* margin-top: -1%; */
    justify-content: center;
    align-items: center;
    height: 20vh;
    width: 100%;
    background-color: #343231;
    position: absolute;
  
    color: white;
  }

  .instrucFooter {
    display: flex;
    /* margin-top: -1%; */
    justify-content: center;
    align-items: center;
    height: 20vh;
    width: 100%;
    background-color: #343231;
    position: absolute;
  
    color: white;
  }

  @media (max-width: 768px) {
    .footer {
      height: auto;
      position: relative;
      margin-top: -30%;
     
    }
    .row {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .col {
      margin-top: 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-left: 0;
    }
    .col:first-child[style] {
      margin-left: 0 !important;
      width: 100%;
      height: auto
    }
    .col:nth-child(3) {
      margin-bottom: 5%;
    }
    .social {
      margin-top: 5%;
    }
    .col h3 {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .col ul {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      justify-content: center;
      line-height: 300%;
      align-items: center;
    }
    .col li {
      margin-top: 10%;
      align-self: center;
    }
    .col:nth-child(4)[style] {
      margin-right: 0 !important;
    }
    .row:last-child[style] {
      margin-right: 0;
    }
    .about_footer {
      display: grid;
      height: auto !important;
    }
  }

  @media (max-width: 435px) {
  .footer {
    height: auto;
    position: relative;
    margin-top: -30%;
   
  }
  .row {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .col {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 0;
  }
  .col:first-child[style] {
    margin-left: 0;
    width: 100%;
    height: auto
  }
  .col:nth-child(3) {
    margin-bottom: 5%;
  }
  .social {
    margin-top: 5%;
  }
  .col h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .col ul {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    line-height: 300%;
    align-items: center;
  }
  .col li {
    margin-top: 10%;
    align-self: center;
  }
  .col:nth-child(4)[style] {
    margin-right: 0;
  }
  .row:last-child[style] {
    margin-right: 0;
  }
  .about_footer {
    display: grid;
    height: auto !important;
  }
  }
  