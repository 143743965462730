.sunday {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    font-size: 2em;
    /* justify-content: center; */
    /* align-items: center; */
    text-align: center;
    /* filter: blur(8px); */
  }
  .joinUs {
    margin-top: 1%;
  }
  .joinUs h5 {
    font-size: 0.7em;
    margin-top: -2%;
    font-weight: 300;
  }

  .thumbnails {
    display: flex;
    justify-content: space-evenly;
  }
  .thumbnails img {
    width: 32.5vw;
    /* height: 30vh; */
  }
  .thumbnails img:hover {
    /* filter: blur(2px) */
    filter: brightness(0.4);
  }

  @media (max-width: 1110px) { 

    .sunday {
      height: auto;
    }
    .thumbnails img {
      /* width: 100vw; */
      height: 30vh;
    }

  }

  @media only screen and (max-width: 768px) { 
    .sunday {
      height: auto;
    }
    .thumbnails {
      flex-direction: column;
      margin-bottom: 12%;
    }
    .thumbnails img {
      width: 100vw;
      height: auto;
    }
    /* .member {
      margin-bottom: 47% !important;
    } */

  }

  @media (max-width: 435px) {
    .sunday {
      height: auto;
      font-size: 1.5em; 
      /* display: flex;
      flex-direction: column;
      font-size: 2em; */
      /* justify-content: center; */
      /* align-items: center; */
      /* filter: blur(8px); */
    }
    .thumbnails {
      flex-direction: column;
    }
    .thumbnails img {
      width: 100vw;
      /* height: 30vh; */
    }

  }
