#root {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background-color: #462770
}
/* .body {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  background-color: "black";
} */
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.header img {
  width: 100%;
}

/* end App.js */


.choiceButton button:hover {
  background-color: rgba(250, 249, 246, 0.3);
  transition: 0.7s;
  cursor: pointer;
}

#about {
  margin-left: 10%;
}
#echurch {
  margin-left: 10%;
  margin-right: 10%;
}
#echoir {
  margin-right: 10%;
}

@keyframes slideMe {
  0% {
    transform: skewX(0deg) translate(-100vw);
  }
  100% {
    transform: skew(0deg);
  }
}

button {
  width: 16vw;
  height: 3.5vh;
  margin-top: 1vh;
  background-color: black;
  color: white;
}

li {
  margin-top: 4%;
  padding-right: 10%;
}

li:first-child {
  margin-top: 0;
}


.blurFilter {
  position: absolute;
  height: 200vh;
  width: 100vw;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
}

.social-container {
  padding-left: 30px;
}
a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}
a.social:hover {
  transform: translateY(-6px);
}
a.youtube {
  color: #eb3223;
}

a.facebook {
  color: #4968ad;
}

a.twitter {
  color: #49a1eb;
}

a.instagram {
  color: black;
}



/* mobile media query */

@media (max-width: 768px) { 
  .body {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header img {
    margin-top: 9%;
    height: 70vh
  }
  li {
    margin-top: 8%;
  }

}
#instrucText {
  /* display: none; */
}

@media (max-width: 428px) {
  .body {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header img {
    margin-top: 8%;
    height:55vh
  }
  li {
    margin-top: 8%;
  }
  

}